const CKFormVO = () => {
  return {
    id: '',
    department: {
      id: ''
    },
    location: '',
    reason: '', //出库原因
    time: '',
    orgId: 0,
    type: 'CK',
    facility: {
      id: null
    }
  }
}

export default CKFormVO