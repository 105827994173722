<template>
  <a-spin :spinning="ifSub">
  <a-form :form="form">
    <a-form-item label="出库日期" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-date-picker style="width: 100%"
                     v-decorator="['time',{initialValue:moment(new Date(),'YYYY-MM-DD'),rules: [{ required: true, message: '请选择时间' }]}]"
                     placeholder="请选择日期"></a-date-picker>
    </a-form-item>
    <a-form-item  label="出库人"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-select disabled v-decorator="['userId',{rules: [{ required: true, message: '请选择巡检人' }],trigger:'change'}]">
        <a-select-option v-for="item in userList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item  label="所属部门"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-tree-select :treeData="deptList" @change="selectDept"  v-decorator="['deptId',{rules: [{ required: true, message: '请选择部门' }],trigger:'change'}]">
      </a-tree-select>
    </a-form-item>
    <a-form-item label="出库原因" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-input  v-decorator="['reason']" placeholder="请输入出库原因"></a-input>
    </a-form-item>
    <a-form-item label="安装位置"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-tree-select
        :treeData="areaList"
        showSearch
        placeholder="选择省市"
        allowClear
        treeDefaultExpandAll
        v-decorator="['areaId',{rules: [{ required: true, message: '请选择省市' }]}]"
      ></a-tree-select>
      <a-input  v-decorator="['location']" placeholder="请输入地址"></a-input>
    </a-form-item>
    <a-form-item  label="所属设施"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-select  v-decorator="['facilityId']" placeholder="请选择设施">
        <a-select-option v-for="item in facilityList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
  </a-spin>
</template>

<script>
  import CKFormVO from './common/CKFormVO'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  export default {
    props: {
    },
    name: 'testForm',
    data () {
      return {
        ifSub: false,
        form: this.$form.createForm(this),
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        formItem: CKFormVO(),
        userList: [],
        areaList: [],
        deptList: [],
        id: '',
        deviceCondition: '',
        facilityList: [],
        equipmentDepartment: ''
      }
    },
    created () {

    },
    methods: {
      moment,
      loadFunction() {
        this.loadCheckUserList()
        this.loadDept()
        this.loadAreaList()
        this.loadUser()
        this.getFacilityList(this.currentDeptId().organizationId)
      },
      selectDept(value) {
        this.getFacilityList(value)
      },
      getFacilityList(value) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.selectFacility,
          noTips: true,
          data: { departmentId: value },
          success: (data) => {
            this.facilityList = data.body
          }
        })
      },
      loadUser() {
        this.form.setFieldsValue({
          userId: this.$store.getters.currentUser.id
        })
      },
      loadDept() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
        this.form.setFieldsValue({
          deptId: this.currentDeptId().organizationId + ''
        })
      },
      loadAreaList() {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.areaTree,
          noTips: true,
          success: (data) => {
            this.areaList = data.body
          }
        })
      },
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      setVOFields(values) {
        this.formItem = {
          area: {
            id: values.areaId
          },
          department: {
            id: values.deptId
          },
          user: {
            id: values.userId
          },
          location: values.location,
          reason: values.reason, //出库原因
          time: values.time,
          deviceCondition: this.deviceCondition,
          orgId: 0,
          type: 'CK',
          facility: {
            id: values.facilityId
          }
        }
      },
      handleAddSubmit() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.equipment2.equipmentInfoApi.transfer,
              data: this.formItem,
              noTips: true,
              params: {
                id: this.id
              },
              success: (data) => {
                this.$emit('CKSucceed')
              }
            })
            this.form.resetFields()
          } else {
            this.$emit('addErr')
          }
        })
      },
      getEquipmentInfo(id) {
        this.loadFunction()
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.view,
          noTips: true,
          params: {
            id: id
          },
          success: (data) => {
           this.id = id
            this.deviceCondition = data.body.deviceInfo.deviceCondition
            this.setFields(data.body)
          }
        })
      },
      setFields(data) {
        this.equipmentDepartment = data.deviceInfo.department.id
      },

    }
  }
</script>

<style scoped>

</style>