<template>
  <a-spin :spinning="ifSub">
  <a-form :form="form">
    <a-form-item  label="所属部门"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-tree-select :treeData="deptList" @change="selectDept" placeholder="请选择部门"  v-decorator="['deptId',{rules: [{ required: true, message: '请选择部门' }],trigger:'change'}]">
      </a-tree-select>
    </a-form-item>
    <a-form-item   label="源设备仓库"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-select disabled v-decorator="['depotsId']" placeholder="请选择仓库">
        <a-select-option v-for="item in yanDepotsList" :key="item.id" :value="item.id"  >{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item  label="目标仓库"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-select placeholder="请选择仓库" v-decorator="['goDepotsId',{rules: [{ required: true, message: '请仓库部门' }]}]">
        <a-select-option v-for="item in depotsList" :key="item.id" :value="item.id"  >{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="调拨日期" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-date-picker style="width: 100%"
                     v-decorator="['time',{initialValue:moment(new Date(),'YYYY-MM-DD'),rules: [{ required: true, message: '请选择时间' }]}]"
                     placeholder="请选择日期"></a-date-picker>
    </a-form-item>
    <a-form-item  label="调拨人"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-select placeholder="请选择调拨人" disabled  v-decorator="['userId',{rules: [{ required: true, message: '请选择巡检人' }],trigger:'change'}]">
        <a-select-option v-for="item in userList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="调拨原因" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-input  v-decorator="['reason']" placeholder="请输入调拨原因"></a-input>
    </a-form-item>
  </a-form>
  </a-spin>
</template>

<script>
  import DBFormVO from './common/DBFormVO'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  export default {
    props: {

    },
    name: 'testForm',
    data () {
      return {
        ifSub: false,
        form: this.$form.createForm(this),
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        formItem: DBFormVO(),
        userList: [],
        deptList: [],
        id: '',
        deviceCondition: '',
        yanDepotsList: [],
        depotsList: []
      }
    },
    created () {
    },
    methods: {
      moment,
      loadfunction() {
        this.loadCheckUserList()
        this.loadDept()
        this.loadUser()
        this.selectDept(this.currentDeptId().organizationId)
        this.loadCheckDepotsList()
      },
      selectDept (value) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.selectDepots,
          noTips: true,
          data: {
            departmentId: value
          },
          success: (data) => {
            this.depotsList = data.body
          }
        })
      },
      loadUser() {
        this.form.setFieldsValue({
          userId: this.$store.getters.currentUser.id
        })
      },
      loadDept() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
        this.form.setFieldsValue({
          deptId: this.currentDeptId().organizationId + ''
        })
      },
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      setVOFields(values) {
        this.formItem = {
          department: {
            id: values.deptId
          },
          depot: {
            id: values.goDepotsId
          },
          user: {
            id: values.userId
          },
          reason: values.reason,
          time: values.time,
          deviceCondition: this.deviceCondition,
          orgId: 0,
          type: 'DB'
        }
      },
      handleAddSubmit() {
        this.form.validateFields((err, values) => {
          if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.equipment2.equipmentInfoApi.transfer,
            data: this.formItem,
            params: {
              id: this.id
            },
            success: (data) => {
              this.$emit('DBSucceed')
            }
          })
          this.form.resetFields()
          }
        })
      },
      getEquipmentInfo(id) {
        this.loadfunction()
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.view,
          noTips: true,
          params: {
            id: id
          },
          success: (data) => {
            this.id = id
            this.deviceCondition = data.body.deviceInfo.deviceCondition
            this.setFields(data.body)
          }
        })
      },
      setFields(data) {
        this.form.setFieldsValue({
          depotsId: data.deviceInfo.depot.id
        })
      },
      loadCheckDepotsList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.selectDepots,
          noTips: true,
          success: (data) => {
            this.yanDepotsList = data.body
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>