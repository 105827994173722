<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">库存设备基本信息</h1>
    </div>
    <statistics ref="statistics"  :treeData="treeData" :sup_this="sup_this" @deleteSucceed="deleteSucceed"></statistics>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="content">
        <a-row :gutter="12">
          <a-col  :sm="9" :md="9" :lg="6" :xl="5" >
              <equipment-type-tree :treeData = treeData :sup_this="sup_this"></equipment-type-tree>
          </a-col>
          <a-col :sm="15" :md="15" :lg="18" :xl="19">
              <inventory ref="inventoryEquipment" :sup_this="sup_this" :categoryId="categoryId" :departmentId="departmentId" :orgRadioType="orgRadioType"></inventory>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </page-layout>
</template>
<script>
  import statistics from './statistics/List'
  import equipmentTypeTree from './equipmentTypeTree/List'
  import inventory from  './inventory/List'
  import PageLayout from '@/components/page/PageLayout'
  const treeData = []
  export default {
    name: 'EquipmentInfo',
    components: { statistics, equipmentTypeTree, inventory, PageLayout },
    data () {
      return {
        sup_this: this,
        treeData,
        categoryId: '',
        departmentId: '',
        orgRadioType: 'orgAll',
      }
    },
    created() {

    },
    methods: {
      slectTab(key) {
        if (key === 'zx') {
          this.$nextTick(() => {
            this.$refs.onLineEquipment.customResetForm()
          })
        } else {
          this.$nextTick(() => {
            this.$refs.inventoryEquipment.customResetForm()
          })
        }
      },
      deleteSucceed() {
        this.categoryId = ''
        this.$refs.inventoryEquipment.search()
      }
    }
  }
</script>
<style scoped>

  .head-info{
    border-right: 1px solid rgba(233, 233, 233, 1);
  }
  .head-info>span{
    color: rgba(0,0,0,.45);
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
  }
  .head-info>p{
    color: rgba(0,0,0,.85);
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }

  /*.content{*/
  /*  background: white;*/
  /*  margin-top: 20px;*/
  /*  float: left;*/
  /*  width: 100%;*/
  /*}*/
  .content-left{
    width: 20%;
    float: left;
    border-right: 1px solid rgba(233, 233, 233, 1);
  }
  .content-right{
    width: 80%;
    float: left;
    border-left: 1px solid rgba(233, 233, 233, 1);
  }
  .searchList{
    padding: 20px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
  }


</style>
