const DBFormVO = () => {
  return {
    id: '',
    department: {
      id: ''
    },
    depot: {
      id: ''
    },
    reason: '', //出库原因
    time: '',
    orgId: 0,
    type: 'DB'
  }
}

export default DBFormVO